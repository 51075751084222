<template>
  <ion-page>
    <Header title="Tentang doo.id" />
    <ion-content :fullscreen="true">
      <div class="container">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem accusamus optio aliquam, facilis illo itaque possimus enim in facere. Quae, velit! Tempore voluptatum corrupti asperiores nesciunt minus natus, voluptatibus odit.
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import Header from '@/components/headerPage'
export default {
  components: {
    IonContent,
    IonPage,
    Header
  }
}
</script>

<style>

</style>
